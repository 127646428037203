import { Subject, Observable } from 'rxjs';

const alert = new Subject();

const showLoader = (): void => {
  alert.next(true);
};

const closeLoader = (): void => {
  alert.next(false);
};

export const loaderService: {
  loader: Observable<boolean>;
  showLoader(): void;
  closeLoader(): void;
} = {
  loader: alert.asObservable() as Observable<boolean>,
  showLoader,
  closeLoader,
};
